<template>
  <div class="font-ssp text-eggplant w-full min-h-screen flex flex-row items-center justify-center">
    <div class="max-w-xs m-6 flex flex-col items-center">
      <inline-svg
        :src="require('@/assets/trackuity.svg')"
        fill="#31CCB0"
        width="320"
        height="38"
        class="w-full"
      >
      </inline-svg>
      <fa-icon
        :icon="['far', 'chevron-down']"
        class="text-5xl my-5 sm:my-10"
      />
      <inline-svg
        :src="require('@/assets/itemwise.svg')"
        fill="#4176D0"
        width="320"
        height="55"
        class="w-full"
      >
      </inline-svg>
      <div class="mt-12 sm:mt-16 text-2xl">
        <p>Our item discovery platform is now called <strong>Itemwise</strong>.</p>
        <p class="mt-4">Please follow this link to sign in:</p>
        <p class="mt-6">
          <a
            class="text-blueberry font-bold text-2xl sm:text-3xl"
            href="https://www.itemwise.com/portal"
          >
            <fa-icon
              :icon="['fas', 'arrow-right']"
              class="mr-4 text-xl sm:text-2xl text-blueberry"
            />itemwise.com/portal</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script language="ts">
import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';

export default Vue.extend({
  name: 'Portal',
  components: {
    InlineSvg,
  },
  head: {
    link: [
      {
        id: 'font-source-sans-pro',
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap',
      },
    ],
  },
});
</script>

<style lang="postcss" scoped>
.font-ssp {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.text-eggplant {
  color: #43404f;
}
.text-blueberry {
  color: #4176d0;
}
</style>
